<template>
  <gli-dialog
    v-if="isNew"
    :title="$t('Add new site')"
    :isNew="isNew"
    :editedObject="newSite"
    @save="save"
    @cancel="cancel"
    @click:outside="closeDialog"
  >
    <v-row dense>
      <v-col cols="12">
        <gli-text-field
          v-model="name"
          :label="$t('Name')"
          required
          :rules="[rules.required]"
          :autofocus="isNew"
        ></gli-text-field>
      </v-col>
      <v-col cols="12" v-if="isSuperAdmin">
        <v-select
          item-text="name"
          item-value="id"
          :items="organizations"
          v-model="organizationId"
          :label="$t('Organization')"
          :rules="[rules.required]"
          clearable
        ></v-select>
      </v-col>
    </v-row>
  </gli-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import rules from '../mixins/rules';

export default {
  name: 'SiteDialog',

  data() {
    return {};
  },
  computed: {
    ...mapGetters('site', ['isNew', 'newSite']),
    ...mapGetters('organization', ['organizations']),
    ...mapGetters('account', ['isSuperAdmin']),
    ...mapFields('site', ['newSite.name', 'newSite.organizationId'])
  },

  async mounted() {
    await this.getAllOrganizations();
  },

  methods: {
    ...mapActions('organization', { getAllOrganizations: 'getAll' }),

    ...mapActions('site', {
      save: 'saveNew',
      cancel: 'cancel'
    }),
    closeDialog() {
      this.$emit('input', false);
    }
  },
  mixins: [rules]
};
</script>
